/* Customize styles as needed */
.about-us-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 80px;
    width: 100%;
  }

.about-us-text {
  flex: 1;
}

.about-us-image {
  flex: 1;
  margin-left: 24px;
}

.about-us-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
  