.our-testimonials {
  max-width: 80%;
  padding-top: 80px;
  padding-bottom: 100px;
}

.our-testimonials .section-heading {
  text-align: center;
  margin-bottom: 45px;
}

.our-testimonials .section-heading h2 {
  margin: 0 60px;
  margin-bottom: -10px;
}

.comment-section {
  background-color: #dddada;
  border-radius: 10px;
  padding: 20px;
}

.testimonial-comment {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.bottom-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.person-image {
  width: 100px;
  height: auto;
  border-radius: 50%;
  overflow: hidden;
}

.person-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details {
  margin-left: 20px;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #40659c;
}

.name {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

.owl-testimonials .item img {
  border-radius: 10px;
  transition: all 1.0s;
  height: 65vh;
  width: 500px;
}

.owl-testimonials .owl-nav .owl-prev,
.owl-testimonials .owl-nav .owl-next {
  position: absolute;
  top: 100px;
}

.owl-testimonials .owl-nav .owl-prev {
  left: -55px;
}

.owl-testimonials .owl-nav .owl-next {
  right: -50px;
}

.owl-testimonials .owl-nav .owl-prev span,
.owl-testimonials .owl-nav .owl-next span {
  color: transparent;
}

.owl-testimonials .owl-nav .owl-prev span:hover::after,
.owl-testimonials .owl-nav .owl-next span:hover::after {
  opacity: 1;
}

.owl-testimonials .owl-nav .owl-prev span:after,
.owl-testimonials .owl-nav .owl-next span:after {
  width: 30px;
  height: 30px;
  background-color: #333;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  transition: all .5s;
  opacity: 0.5;
}

.owl-testimonials .owl-nav .owl-prev span:after {
  content: '<';
}

.owl-testimonials .owl-nav .owl-next span:after {
  content: '>';
}

.owl-testimonials .owl-dots {
  text-align: center;
  margin-top: 40px;
}
.owl-testimonials .owl-dots .owl-dot {
  width: 6px;
  height: 6px;
  background-color: #333;
  border-radius: 50%;
  opacity: 0.3;
  margin: 0 5px;
}
.owl-testimonials .owl-dots .active {
  opacity: 1;
  width: 10px;
  height: 10px;
}