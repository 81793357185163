.our-services {
  position: relative;
  width: 80%;
  margin-top: 0px;
  padding-top: 80px;
}

.our-services .container {
  position: relative;
}

.our-services .section-heading {
  text-align: center;
  margin-bottom: 45px;
}

.our-services .section-heading h2 {
  margin: 0 60px;
  margin-bottom: -10px;
}

.our-services .item {
  text-align: center;
  margin: 15px;
  border-radius: 20px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.our-services .item h4 {
  font-size: 20px;
  font-weight: 700;
  color: #2a2a2a;
  margin-bottom: 30px;
  line-height: 30px;
}

.our-services .item .icon {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.our-services .item p {
  margin-top: 15px;
  padding-top: 10px;
}

.our-services .item h5 {
  font-size: 15px;
  margin-top: 35px;
  padding-top: 30px;
  border-top: 1px solid #eee;
}

.owl-services .owl-nav .owl-prev,
.owl-services .owl-nav .owl-next {
  position: absolute;
}

.owl-services .owl-nav .owl-prev {
  left: -55px;
}

.owl-services .owl-nav .owl-next {
  right: -50px;
}

.owl-services .owl-nav .owl-prev span,
.owl-services .owl-nav .owl-next span {
  color: transparent;
}

.owl-services .owl-nav .owl-prev span:hover::after,
.owl-services .owl-nav .owl-next span:hover::after {
  opacity: 1;
}

.owl-services .owl-nav .owl-prev span:after,
.owl-services .owl-nav .owl-next span:after {
  width: 45px;
  height: 45px;
  background-color: #ff695f;
  display: inline-block;
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  transition: all .5s;
  opacity: 0.5;
}

.owl-services .owl-nav .owl-prev span:after {
  content: '<';
}

.owl-services .owl-nav .owl-next span:after {
  content: '>';
}

.owl-services .owl-dots {
  text-align: center;
  margin-top: 40px;
}

.owl-services .owl-dots .owl-dot {
  width: 6px;
  height: 6px;
  background-color: #ff695f;
  border-radius: 50%;
  opacity: 0.3;
  margin: 0 5px;
}

.owl-services .owl-dots .active {
  opacity: 1;
  width: 10px;
  height: 10px;
}
