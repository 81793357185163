.home-container {
  max-width: 100%;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.background-image {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: absolute;
  padding-top: 150px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}

.logo-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 30%;
  max-height: 30%;
  z-index: 1;
  opacity: 1.0;
}

@media (max-width: 430px) {
  .home-container {
    min-height: 90vh;
  }
}