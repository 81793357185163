nav {
    background-color: black;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    max-height: 80px;
    background: transparent;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
}
.navbar-menu-container {
    display: none;
}
.navbar-logo-container {
    max-height: 80px;
    max-width: 120px;
}
.navbar-links-container a {
    margin-right: 3rem;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    color: #2a2a2a;
    height: 30px;
    line-height: 40px;
    letter-spacing: 1px;
}
.navbar-links-container a:hover{
    color: #ff685f;
}

.navbar-cart-icon {
    font-size: 1.15rem;
}
.navbar-menu-container svg {
    font-size: 1.5rem;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .navbar-links-container a {
        margin-right: 1rem;
        font-size: 1rem;
        outline: none;
    }
    .primary-button {
        font-size: 1rem;
    }
    .home-bannerImage-container {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .navbar-logo-container {
        max-width: 100px;
    }
    .navbar-links-container {
        display: none;
    }
    .navbar-menu-container {
        display: flex;
    }
}