.our-portfolio {
  position: relative;
  padding-top: 80px;
  max-width: 90%;
}

.our-portfolio .section-heading {
  text-align: center;
  margin-bottom: 60px;
}

.our-portfolio .section-heading h2 {
  text-align: center;
  position: relative;
  z-index: 1;
}

.our-portfolio .item .thumb {
  position: relative;
}

.our-portfolio .item .thumb:hover .hover-effect {
  bottom: 5%;
}

.our-portfolio .item .thumb:hover img {
  opacity: 0.7;
}

.our-portfolio .item .hover-effect {
  position: absolute;
  background-image: url(../../assets/hover-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .5s;
  border-radius: 10% 0% 10% 0%;
  bottom: -500%;
  right: 5%;
  width: 14vw;
  height: 13vw;
}

.our-portfolio .item .hover-effect .inner-content {
  position: absolute;
  right: 30px;
  bottom: 30px;
  text-align: right;
}

.our-portfolio .item .hover-effect .inner-content h4 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
}

.our-portfolio .item .hover-effect .inner-content span {
  font-size: 15px;
  color: #fff;
}

.our-portfolio .item .thumb img {
  border-radius: 20px;
  transition: all .5s;
  height: 30vw;
  width: 25vw;
}

.owl-portfolio .owl-nav {
  position: absolute;
  top: 40%;
  width: 100%;
  height: 150px;
}

.owl-portfolio .owl-nav .owl-prev,
.owl-portfolio .owl-nav .owl-next {
  position: absolute;
}

.owl-portfolio .owl-nav .owl-prev {
  left: -55px;
}

.owl-portfolio .owl-nav .owl-next {
  right: -55px;
}

.owl-portfolio .owl-nav .owl-prev span,
.owl-portfolio .owl-nav .owl-next span {
  color: transparent;
}

.owl-portfolio .owl-nav .owl-prev span:hover::after,
.owl-portfolio .owl-nav .owl-next span:hover::after {
  opacity: 1;
}

.owl-portfolio .owl-nav .owl-prev span:after,
.owl-portfolio .owl-nav .owl-next span:after {
  width: 45px;
  height: 45px;
  background-color: #ff695f;
  display: inline-block;
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  transition: all .5s;
  opacity: 0.5;
}

.owl-portfolio .owl-nav .owl-prev span:after {
  content: '<';
}

.owl-portfolio .owl-nav .owl-next span:after {
  content: '>';
}

.owl-portfolio .owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-portfolio .owl-dots .owl-dot {
  width: 8px;
  height: 8px;
  background-color: #ff695f;
  border-radius: 50%;
  opacity: 0.4;
  margin: 0px 5px;
}

.owl-portfolio .owl-dots .active {
  opacity: 1;
  width: 15px;
  height: 15px;
}

@media (max-width: 1024px) {
  .our-portfolio .item .hover-effect {
    right: 20%;
  }
}

@media (max-width: 768px) {
  .our-portfolio .item .thumb img {
    height: 48vw;
    width: 40vw;
  }

  .our-portfolio .item .hover-effect {
    right: 8%;
    width: 24vw;
    height: 24vw;
  }
}

@media (max-width: 426px) {
  .our-portfolio .item .thumb img {
    height: 96vw;
    width: 80vw;
  }

  .our-portfolio .item .hover-effect {
    right: 16%;
    width: 48vw;
    height: 48vw;
  }
}