.contact-us-container {
    width: 100%;
    background-color: #efefe9;
    padding-top: 80px;
}

.contact-us-text {
    font-size: 20px;
}


.contact-us-icons-container {
    scale: 1.25;
}

.contact-us-text-container {
    padding-bottom: 15px;
}

.contact-us-icons-container:hover {
    scale: 1.5;
    transition: transform 1.5s;
}

.contact-us-map {
    border-radius:10px;
}

@media (max-width: 1000px) {
    .contact-us-container {
        margin-left: 0px;
        margin-top: 0px;
    }
}