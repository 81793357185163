* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Georgia, serif !important;
  outline: none;
  scroll-behavior: smooth;
  scroll-margin-top: 50px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

html, body {
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  margin: 0rem auto;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f6f6f6;
}
